<template>
    <div class="download-page">

        <div class="banner vivify fadeIn" v-if="bannerList.length>0">
            <img :src="bannerList[0].banner">
        </div>
        <div class="tabBox">
            <div v-for="(item,index) in list" :key="index" class="tab_bar" :class="{tab_bar_item_btna:form.cid == item.id}" @click="TabbarClick(index,item)">
                {{ item.name }}
            </div>
        </div>
        <div class="inputBox">
            <el-input :placeholder="$t('menu.please')" v-model="form.keywords" @keyup.enter.native="searchClick"></el-input>
            <div class="searchBox" @click="searchClick">
                <img src="./img/search.png">
            </div>
        </div>
        <div class="contant" v-if="tableData.length>0">
            <transition-group> <!-- 1️⃣使用 transition-group 标签包裹列表； -->
                <div class="contentBox" v-for="(item,index) in tableData" :key="index">
                    <div class="contentBoxCon">
                        <div class="imageBox">
                            <el-image
                                v-if="item.image"
                                class="image1"
                                :src="item.image"
                                :initial-index="index"
                                :preview-src-list="[item.image]"
                                :preview-teleported="true"
                            ></el-image>
                            <img class="image1" src="./img/pdf.png" v-else/>
                        </div>
                        <div class="valueBox">
                            <div class="text1">{{ item.title }}</div>
                            <div class="text2">{{ $t('download.size') }}{{ item.url_size }}MB</div>
                            <div class="text3">
                                <div class="time">190 {{ $t('download.download') }} {{ $t('download.time') }} {{ item.update_time }}</div>
                                <a :href="item.url" @click="handleExport(item)">
                                    <div class="buttonBox">
                                        <img class="image2" src="./img/download.png"/>
                                        <div>{{ $t('download.downloadN') }}</div>
                                    </div>
                                </a>

                            </div>
                        </div>
                    </div>
                </div>
            </transition-group>
        </div>
        <div v-else style="margin-bottom: 100px">
            <el-empty description="暂无数据" :image="require('./img/wuImage.png')"></el-empty>
        </div>
        <el-pagination background @current-change="handleCurrentChange" :current-page.sync="form.page" :pager-count="7" :page-size="Number(searchData.limit)"
                       layout="prev, pager, next" :hide-on-single-page="true" :total="searchData.total_count">
        </el-pagination>

    </div>
</template>
<script>
import '../assets/css/common.css'
// import './scss/home-page.scss'
import '@/views/scss/Download.scss'

export default {
    name: "Download",
    data() {
        return {
            list: [],
            bannerList: [],
            searchData: [],
            tableData: [],
            form: {
                keywords: '',
                page: 1,
                limit: 5,
                cid: '',
            },
        }
    },
    created() {
        this.Interface()
    },
    mounted() {
        this.sortList()
    },
    methods: {
        async handleExport(res) {
            // await this.$axios.post('api/zip/zip_read', this.$qs.stringify(
            //     {
            //         id:res.id
            //     }
            // )).then(res => {
            //     //消息接口
            //     this.$store.dispatch('GetXiao')
            // })
        },
        /**
         * tab点击
         * */
        TabbarClick(index, item) {
            this.form.cid = item.id
            this.searchClick()
        },
        /**搜索按钮点击*/
        searchClick() {
            this.form.page = 1
            this.InterfaceList()
        },
        handleCurrentChange(val) {
            if (this.form.page) {
                this.form.page = val;
            }
            this.InterfaceList()
        },
        async Interface() {
            //公司信息
            await this.$axios.post('pcapi/index/banner', {
                type: 6
            }).then(res => {
                if (res.data.code == 1) {
                    this.bannerList = res.data.data
                }
            })
        },
        async sortList() {
            //分类列表
            await this.$axios.post('pcapi/index/information_cate', {}).then(res => {
                if (res.data.code == 1) {
                    this.list = res.data.data
                    if (this.list.length > 0) {
                        this.form.cid = this.list[0].id
                        this.InterfaceList()
                    }
                }
            })
        },
        async InterfaceList() {
            //资料列表
            await this.$axios.post('pcapi/index/information', {
                ...this.form
            }).then(res => {
                if (res.data.code == 1) {
                    this.searchData = res.data.data
                    this.tableData = res.data.data.list
                }
            })
        },
    }

}
</script>
<style>

</style>
